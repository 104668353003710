import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/navBar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/home"),
      },
    ],
  },
  // 解决方案
  {
    path: "/solutionCount",
    component: Layout,
    children: [
      {
        path: "",
        name: "SolutionCount",
        component: () => import("@/views/solutionCount"),
      },
    ],
  },
  // 解决方案内页
  {
    path: "/solutionInside/:solutionId",
    component: Layout,
    children: [
      {
        path: "",
        name: "SolutionInside",
        component: () => import("@/views/solutionInside"),
      },
    ],
  },
  // 关于我们
  {
    path: "/aboutUs",
    component: Layout,
    children: [
      {
        path: "",
        name: "AboutUs",
        component: () => import("@/views/aboutUs"),
      },
    ],
  },
  // 联系我们
  {
    path: "/contact",
    component: Layout,
    children: [
      {
        path: "",
        name: "Contact",
        component: () => import("@/views/contact"),
      },
    ],
  },
  // 新闻动态
  {
    path: "/news",
    component: Layout,
    children: [
      {
        path: "",
        name: "News",
        component: () => import("@/views/news"),
      },
    ],
  },
  {
    path: "/newsDetails/:newsId",
    component: Layout,
    children: [
      {
        path: "",
        name: "NewsDetails",
        component: () => import("@/views/newsDetails"),
      },
    ],
  },
  // 产品服务
  {
    path: "/product",
    component: Layout,
    children: [
      {
        path: "",
        name: "Product",
        component: () => import("@/views/product"),
      },
    ],
  },
  {
    path: "/productDetails/:productId",
    component: Layout,
    children: [
      {
        path: "",
        name: "ProductDetails",
        component: () => import("@/views/productDetails"),
      },
    ],
  },
  // 视频展示
  {
    path: "/video",
    component: Layout,
    children: [
      {
        path: "",
        name: "Video",
        component: () => import("@/views/video"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
