import Vue from "vue";
import "element-ui/lib/theme-chalk/index.css";
import {
  Pagination,
  Dialog,
  Input,
  Form,
  FormItem,
  Icon,
  Button,
  Message,
} from "element-ui";
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Icon);
Vue.use(Button);
Vue.prototype.$message = Message;
