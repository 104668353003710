/** 获取首页信息 */
import request from "@/utils/request";

/** 获取首页信息 */
export function homepageInfo(params) {
  return request({
    url: "/web/homepage/info",
    method: "get",
    params,
  });
}
/** 通过产品id产品内页信息 */
export function productInfo(params) {
  return request({
    url: "/web/product/info",
    method: "get",
    params,
  });
}
/** 获取产品服务页信息 */
export function productServer(params) {
  return request({
    url: "/web/product/server",
    method: "get",
    params,
  });
}

/** 通过id获取新闻内页信息 */
export function newsInfo(params) {
  return request({
    url: "/web/news/info",
    method: "get",
    params,
  });
}
/** 新闻列表 */
export function newsList(params) {
  return request({
    url: "/web/news/list",
    method: "get",
    params,
  });
}
/** 获取解决方案页信息 */
export function solutionInfo(params) {
  return request({
    url: "/web/solution/info",
    method: "get",
    params,
  });
}
/** 方案详情 */
export function solutionDetails(params) {
  return request({
    url: "/web/solution/details",
    method: "get",
    params,
  });
}

/** 视频展示页产品分类 */
export function videoClassification(params) {
  return request({
    url: "/site/video/classification",
    method: "get",
    params,
  });
}

/** 视频列表 */
export function videoList(params) {
  return request({
    url: "/site/video/list",
    method: "get",
    params,
  });
}

/** 登录 */
export function login(data) {
  return request({
    url: "/customer/login",
    method: "post",
    data,
  });
}
