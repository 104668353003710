import axios from "axios";
import { Message } from "element-ui";
import router from "../router";
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: window.config.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (
      config.url === "/site/video/classification" ||
      config.url === "/site/video/list"
    ) {
      if (window.localStorage.getItem("zfkj_clientToken")) {
        // do something before request is sent
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers["Authorization"] = `Bearer ${window.localStorage.getItem(
          "zfkj_clientToken"
        )}`;
      }
    }

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.status !== 200 && res !== "注销成功") {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.message === "登录超时,请重新登录") {
        // to re-login
        window.localStorage.removeItem("zfkj_clientToken");
        router.push("/solutionCount");
      }
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    if (error.message === "Network Error") {
      Message({
        message: "网络出错，请稍后重试",
        type: "error",
        duration: 5 * 1000,
      });
    } else if (error.message === "timeout of 5000ms exceeded") {
      Message({
        message: "网络连接超时，请稍后再试",
        type: "error",
        duration: 5 * 1000,
      });
    } else if (error.message === "Request failed with status code 500") {
      Message({
        message: "内部服务器错误",
        type: "error",
        duration: 5 * 1000,
      });
    }
    return Promise.reject(error);
  }
);

export default service;
