import router from "./router";
router.beforeEach(async (to, from, next) => {
  const token = window.localStorage.getItem("zfkj_clientToken");
  if (to.path !== "/video") {
    next();
  } else if (
    (to.path === "/video" && from.path === "/solutionCount") ||
    (from.path === "/" && token)
  ) {
    next();
  } else {
    router.back();
  }
});
router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
});
