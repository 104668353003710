import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import "./style/index.scss";
// 轮播插件
// import VueAwesomeSwiper from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
// Vue.use(VueAwesomeSwiper);
import "@/utils/elementUi.js";
import LightTimeline from "vue-light-timeline";

Vue.use(LightTimeline);
// import { Pagination, Dialog } from "element-ui";
// Vue.use(Pagination);
// Vue.use(Dialog);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
