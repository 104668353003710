<template>
  <div class="layout">
    <div class="navBar">
      <div class="nav">
        <div class="navLeft">
          <div>
            <img
              src="@/assets/home/logo.png"
              alt=""
              @click="toPath({ navbarName: '网站首页' })"
            />
            <div>— 允执其中 远举高飞 —</div>
          </div>
          <i></i>
          <div>
            <div>专注医学领域</div>
            <div>智慧医教管控建设</div>
          </div>
        </div>

        <ul class="navList">
          <li
            v-for="item in navList"
            :key="item.navbarId"
            @click="toPath(item)"
          >
            {{ item.navbarName }}
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
    <div class="footer">
      <div class="footer_content">
        <div class="footer_left">
          <ul class="footer_list">
            <li
              v-for="item in navList"
              :key="item.navbarId"
              @click="toPath(item)"
            >
              {{ item.navbarName }}
            </li>
          </ul>
          <span
            >Copyright © 2008 - 2022 山东中飞科技有限公司
            <a
              href="https://beian.miit.gov.cn/"
              style="color: #fff; text-decoration: none"
              >鲁IPC备13003179号-1</a
            >
          </span>
        </div>
        <div class="footer-right">
          <div style="margin-bottom: 18px">
            <span>咨询电话</span>
            <br />
            <span> 0531-88871088 </span>
          </div>
          <div>
            <img
              src="@/assets/home/QR.jpg"
              alt=""
              style="display: block; margin-bottom: 13px"
            />
            <span>扫码关注公众号</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homepageInfo } from "@/api/web.js";
export default {
  name: "",
  data() {
    return {
      navList: [],
    };
  },
  created() {
    this.getHomeInfo();
  },
  methods: {
    toPath(item) {
      switch (item.navbarName) {
        case "关于中飞":
          if (this.$route.path === "/aboutUs") {
            break;
          }
          this.$router.push("/aboutUs");
          break;
        case "联系我们":
          if (this.$route.path === "/contact") {
            break;
          }
          this.$router.push("/contact");
          break;
        case "解决方案":
          if (this.$route.path === "/solutionCount") {
            break;
          }
          this.$router.push("/solutionCount");
          break;
        case "新闻动态":
          if (this.$route.path === "/news") {
            break;
          }
          this.$router.push("/news");
          break;
        case "产品服务":
          if (this.$route.path === "/product") {
            break;
          }
          this.$router.push("/product");
          break;
        case "网站首页":
          if (this.$route.path === "/home") {
            break;
          }
          this.$router.push("/");
          break;
      }
    },
    async getHomeInfo() {
      const { data } = await homepageInfo();
      this.navList = data.navbar;
    },
  },
};
</script>

<style scoped lang="scss">
.layout {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  // overflow: hidden;
}

.navBar {
  width: 100%;
  height: 99px;
  background-color: #eaeaea;
  border-bottom: 2px solid #dbdbdb;
  .nav {
    display: flex;
    margin: 0 auto;
    width: 1633px;
    line-height: 99px;
    .navLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 115px;
      & > div {
        line-height: initial;
      }
      & > div:first-of-type {
        margin-top: 5px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #181818;
        img {
          cursor: pointer;
          vertical-align: middle;
        }
      }
      i {
        display: inline-block;
        margin: 0 19px;
        height: 60px;
        width: 2px;
        background: #27499d;
      }
      & > div:last-of-type {
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 28px;
        color: #27499d;
      }
    }

    .navList {
      float: right;
      li {
        float: left;
        margin-left: 135px;
        font-size: 21px;
        font-family: "微软雅黑";
        cursor: pointer;
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
  }
}
.footer {
  padding-top: 46px;
  width: 100%;
  height: 340px;
  box-sizing: border-box;
  background: #424242;
  .footer_content {
    display: flex;
    justify-content: space-between;
    width: 1460px;
    margin: 0 auto;
    .footer_left {
      .footer_list {
        display: flex;
        padding-bottom: 204px;
        li {
          margin-right: 122px;
          font-size: 21px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
      span {
        font-size: 21px;
        color: #ffffff;
      }
    }
    .footer-right {
      font-size: 21px;
      color: #ffffff;
      line-height: 30px;
    }
  }
}
</style>
