<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    document.body.onselectstart = function () {
      return false;
    };
    document.getElementById("app").oncontextmenu = function () {
      return false;
    };
  },
};
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
